#header-img{
  opacity: 0;
  width: 6rem;
  height: 6rem;
  position: relative;  

}
#body-img{
  width: 6rem;
  height: 6rem;
  position:absolute;
  
}
.App {
  text-align: center;
}
.App-desc{
  position: absolute;
  font-family : 'Roboto';
  padding : 5rem;
  opacity: 0;
}
.App-title
{
  font-size: 10vmin;
  font-family: 'Train One';
  position: absolute;
}
.App-subtitle{
  font-size: 4.5vmin;
  font-family: 'Train One';
}
:root{
  --primary : theme('colors.primaryT');
  --secondary : theme('colors.secondaryT');
  --tertiary : theme('colors.tertiaryT');
}
#header{
  opacity: 0;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

